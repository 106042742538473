import { Box, CardContent, LinearProgress, Tab, Tabs } from '@mui/material'
import { DataGrid, FooterPropsOverrides, GridPaginationModel } from '@mui/x-data-grid'
import { useMerchantsList } from '@src/data/api/merchants-api/merchants-api'
import { MerchantFilter } from '@src/data/types/Filter'
import {
  getNumberFromQueryString,
  getStringArrayFromQueryString,
  getStringFromQueryString,
} from '@src/services/http-common'
import React, { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { EFinancingProgram, EMerchantType } from '@src/data/types/Constants'
import { DataGridFooter } from '@src/components'
import useTerritoryDictionaryById from '@src/data/hooks/territories-hooks'
import TabPanel from '../../components/TabPanel'
import BannersList from './BannersList'
import { useSelectedCompany } from '../../contexts/SelectedCompanyProvider'
import BrowseMerchantToolbar from './components/browseMerchantToolbar'
import { useMerchantColumns, useSelectedCompanyPrograms } from './merchantHooks'

const MerchantList = (): JSX.Element => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const [selectedMerchantTypeId, setSelectedMerchantTypeId] = useState(EMerchantType.merchant)

  const selectedCompany = useSelectedCompany()

  const selectedCompanyPrograms = useSelectedCompanyPrograms(selectedCompany)

  const [pagination, setPagination] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: getNumberFromQueryString(searchParams, 'limit', 25),
  })

  const navigate = useNavigate()

  const updateSearch = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const query: MerchantFilter = useMemo(() => {
    return {
      limit: getNumberFromQueryString(searchParams, 'limit', 25),
      financingProgramIds: getStringArrayFromQueryString(searchParams, 'financingProgramIds'),
      nameContains: getStringFromQueryString(searchParams, 'name', ''),
      offset: getNumberFromQueryString(searchParams, 'offset', 0),
      merchantTypeId: selectedMerchantTypeId,
      isActive: selectedMerchantTypeId === EMerchantType.merchant,
      financingCompanyId: selectedCompany,
    } as MerchantFilter
  }, [searchParams, selectedCompany, selectedMerchantTypeId])

  const [financingProgramIds, setFinancingProgramIds] = React.useState<EFinancingProgram[]>(
    query.financingProgramIds as EFinancingProgram[],
  )

  const [merchantsList, isFetchingMerchantsList] = useMerchantsList(query)
  const [territoriesById, isLoadingTerritories] = useTerritoryDictionaryById(merchantsList)

  const columns = useMerchantColumns(territoriesById)

  const updateUriQuery = useCallback(() => {
    navigate(
      {
        pathname: location.pathname,
        search: createSearchParams(updateSearch).toString(),
      },
      { replace: true },
    )
  }, [location.pathname, navigate, updateSearch])

  React.useEffect(() => {
    updateSearch.set('offset', encodeURIComponent(query.limit * pagination.page))

    updateSearch.set('limit', encodeURIComponent(pagination.pageSize))

    updateUriQuery()
  }, [pagination, query.limit, updateSearch, updateUriQuery])

  const handleSearch = (value: string) => {
    updateSearch.set('name', encodeURIComponent(value))
    updateUriQuery()
  }

  React.useEffect(() => {
    setFinancingProgramIds([])
  }, [selectedCompany])

  React.useEffect(() => {
    updateSearch.set('financingProgramIds', financingProgramIds.join(','))
    updateUriQuery()
  }, [financingProgramIds, updateSearch, updateUriQuery])

  const handleTabChange = (event: React.ChangeEvent<object>, newValue: number) => {
    setSelectedMerchantTypeId(newValue)
  }

  return (
    <Box sx={{ textAlign: 'center', height: '45rem' }}>
      <BrowseMerchantToolbar
        handleSearch={handleSearch}
        nameInitValue={query.nameContains}
        financingProgramIds={financingProgramIds}
        setFinancingProgramIds={setFinancingProgramIds}
        availableProgramIds={selectedCompanyPrograms}
      />

      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'left', marginLeft: '30px' }}>
          <Tabs value={selectedMerchantTypeId} onChange={handleTabChange}>
            <Tab label={t('common.merchant')} />
            <Tab label={t('common.banner')} />
          </Tabs>
        </div>
        <TabPanel value={selectedMerchantTypeId} index={EMerchantType.merchant}>
          {isFetchingMerchantsList || isLoadingTerritories ? (
            <LinearProgress style={{ height: '10px' }} />
          ) : (
            <DataGrid
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: query.limit,
                  },
                },
              }}
              onPaginationModelChange={(model: GridPaginationModel) => setPagination(model)}
              pageSizeOptions={[25, 50, 100]}
              disableColumnFilter
              paginationMode="server"
              rowCount={merchantsList.length}
              disableRowSelectionOnClick
              rows={merchantsList}
              columns={columns}
              slots={{
                footer: DataGridFooter,
              }}
              slotProps={{
                footer: {
                  disabledPreview: query.offset === 0,
                  disabledNext: merchantsList.length < query.limit,
                  pagination,
                  setPagination,
                } as FooterPropsOverrides,
              }}
            />
          )}
        </TabPanel>
        <TabPanel value={selectedMerchantTypeId} index={EMerchantType.banner}>
          {isFetchingMerchantsList || isLoadingTerritories ? (
            <LinearProgress style={{ height: '10px' }} />
          ) : (
            <BannersList bannersList={merchantsList} />
          )}
        </TabPanel>
      </CardContent>
    </Box>
  )
}

export default MerchantList
