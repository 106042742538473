import i18n from '@src/services/i18n'

export function FormatCurrencyNoDecimals(data?: number | null) {
  const val = data?.toLocaleString(`${i18n.language}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return val !== undefined && val !== null ? val : ''
}

export function FormatCurrencyToNearestLowerMultipleToNoDecimals(data: number, roundDownTo: number) {
  let amount = 0
  amount = parseFloat(data.toString())
  amount -= Math.round(amount) % roundDownTo

  const val = amount.toLocaleString(`${i18n.language}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return val !== undefined && val !== null ? val : ''
}

export function FormatCurrencyToNearestUpperMultipleToNoDecimals(data: number, roundUpTo: number) {
  let amount = 0
  amount = parseFloat(data.toString())
  amount += roundUpTo - (Math.round(amount) % roundUpTo)

  const val = amount.toLocaleString(`${i18n.language}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return val !== undefined && val !== null ? val : ''
}

export function FormatCurrencyRoundedUpToNoDecimals(data: number) {
  const val = Math.ceil(data).toLocaleString(`${i18n.language}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return val !== undefined && val !== null ? val : ''
}

export function FormatCurrency(data?: number | string | null | undefined) {
  let amount = 0
  if (data) {
    amount = parseFloat(data.toString())
  }
  const positifAmount = amount < 0 ? amount * -1 : amount
  let val = positifAmount.toLocaleString(`${i18n.language}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  val = amount < 0 ? `(${val})` : val
  return val !== undefined && val !== null ? val : ''
}

export function formatNumber(data?: number | null | undefined) {
  const val = data?.toLocaleString(`${i18n.language}-CA`, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
  return val !== undefined && val !== null ? val : ''
}

export const formatDateTime = (date: Date | null | undefined) =>
  !date ? '' : new Date(date).toLocaleString('en-CA', { hour12: true })

export const formatDate = (date: Date | null | string | undefined): string => {
  if (!date) return ''
  let dateString = date.toString()

  if (dateString.split('T') && dateString.split('T').length === 1) dateString = dateString.concat('T00:00:00')
  if (Date.parse(dateString)) return new Date(dateString).toLocaleDateString('en-CA')

  return date.toString()
}

export const formatIsoDate = (date: Date) => {
  const d = new Date(date)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return [year, month, day].join('-')
}

export const formatTime = (date: Date | null | string | undefined) => {
  if (!date) return ''
  let dateString = date.toString()

  if (dateString.split('T') && dateString.split('T').length === 1) dateString = dateString.concat('T00:00:00')
  if (Date.parse(dateString)) return new Date(dateString).toLocaleTimeString('en-CA')

  return date
}

export const formatPhone = (phoneNumber: string | undefined | null): string => {
  if (!phoneNumber) return 'N/A'
  return `${phoneNumber?.slice(0, 3)}-${phoneNumber?.slice(3, 6)}-${phoneNumber?.slice(6)}`
}

export const removeDiacritics = (text: string): string => {
  if (!text.trim()) {
    return text
  }

  const normalizedText = text.normalize('NFD')
  const removedDiacritics = normalizedText.replace(/[\u0300-\u036f]/g, '')
  return removedDiacritics.normalize('NFC')
}
