import { IconButton, Link, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { EFinancingCompany, EFinancingProgram, ELanguage } from '@src/data/types/Constants'
import { Merchant } from '@src/data/types/Merchant'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@mui/icons-material/Language'

export function useSelectedCompanyPrograms(selectedCompany: EFinancingCompany) {
  return useMemo(() => {
    if (selectedCompany === EFinancingCompany.IFinance) {
      return [EFinancingProgram.Personal]
    }

    return [EFinancingProgram.Auto, EFinancingProgram.IfXpress, EFinancingProgram.Products]
  }, [selectedCompany])
}

export function useMerchantColumns(territoriesById: Record<string, string>) {
  const { t } = useTranslation()

  const columns = useMemo<GridColDef<Merchant>[]>(
    () => [
      {
        field: 'id',
        headerName: '#',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<Merchant, string>) => {
          return <Link href={`/merchant/${params.value}/view`}>{params.value?.split('*').join(' ')}</Link>
        },
      },
      {
        field: 'name',
        headerName: t('common.fullName'),
        flex: 1,
      },
      {
        field: 'languageId',
        headerName: t('common.language'),
        renderCell: (params: GridRenderCellParams<Merchant, ELanguage>) => {
          return <Typography>{t(`common.languageId.${params.value!}`)}</Typography>
        },
      },
      {
        field: 'province',
        headerName: 'Province',
        headerAlign: 'center',
        align: 'center',
      },

      {
        field: 'territory',
        headerName: t('common.territory'),
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        renderCell: (params: GridRenderCellParams<Merchant, string>) => {
          const territoryName = territoriesById[params.row.territoryId || '']
          return (
            <Typography style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} variant="body2">
              {territoryName || t('merchant.noTerritory')}
            </Typography>
          )
        },
      },

      {
        field: 'supportedFinancingPrograms',
        headerName: t('common.program'),
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams<Merchant, EFinancingProgram[]>) => {
          return (
            <div>{params.value?.map((value) => <div key={value}>{t(`common.eFinancingProgram.${value}`)}</div>)}</div>
          )
        },
        cellClassName: 'normalLineHeight',
      },
      {
        field: 'website',
        headerName: t('common.website'),
        renderCell: (params: GridRenderCellParams<Merchant, string>) => {
          return (
            <IconButton disabled={!params.value}>
              <Link href={params.value} target="_blank">
                <LanguageIcon color={!params.value ? 'disabled' : 'inherit'} />
              </Link>
            </IconButton>
          )
        },
      },
    ],
    [t, territoriesById],
  )

  return columns
}
