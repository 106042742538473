import {
  Box,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import yup from '@src/data/types/common/yup-extended'
import { DATE_MAX } from '@src/data/types/Constants'
import { MerchantPaymentPlan } from '@src/data/types/MerchantPaymentPlan'
import { formatDate, formatNumber } from '@src/services/Formatter'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InferType } from 'yup'

interface MerchantPromoPanelProps {
  availablePaymentPlans: MerchantPaymentPlan[]
  isPlanValid: boolean
}

export const MerchantPromoSchema = yup.object().shape({
  defaultAppliedPromoCode: yup.string().defined().nullable(),
})

const MerchantPromoPanel: React.FC<MerchantPromoPanelProps> = ({ availablePaymentPlans, isPlanValid }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<InferType<typeof MerchantPromoSchema>>()

  return (
    <Box>
      <Typography variant="h6" gutterBottom paddingBottom={2}>
        {t('merchant.promo.title')}
      </Typography>
      <Controller
        name="defaultAppliedPromoCode"
        control={control}
        render={({ field: { onChange, value, ...fieldProps } }) => {
          const selectedPlan = availablePaymentPlans?.find((plan) => plan.promoCode === value)

          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <InputLabel id="defaultAppliedPromoCode">{t('merchant.promo.defaultAppliedPromoCode')}</InputLabel>
                <Select
                  {...fieldProps}
                  labelId="defaultAppliedPromoCode"
                  value={value ?? 'none'}
                  onChange={onChange}
                  variant="outlined"
                  fullWidth
                  error={!isPlanValid}
                >
                  <MenuItem value="none">{t('common.none')}</MenuItem>

                  {availablePaymentPlans
                    ?.filter((plan) => {
                      const today = new Date()
                      const dateFrom = new Date(plan.activeFrom)
                      const dateThrough = plan.expiresOn ? new Date(plan.expiresOn) : DATE_MAX
                      return today >= dateFrom && today <= dateThrough
                    })
                    .map((plan) => (
                      <MenuItem key={plan.promoCode} value={plan.promoCode}>
                        {plan.promoCode}
                      </MenuItem>
                    ))}
                  {!isPlanValid && <MenuItem value={value ?? ''}>{value}</MenuItem>}
                </Select>
                {!isPlanValid && (
                  <FormHelperText error>{t('merchant.promo.errors.InactiveDefaultAppliedPromoCode')}</FormHelperText>
                )}
              </Grid>

              {selectedPlan && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      label={t('merchant.promo.interestRate')}
                      value={formatNumber(selectedPlan.interestRate)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t('merchant.promo.reducedInterestRate')}
                      value={formatNumber(selectedPlan.reducedInterestRate)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t('merchant.promo.loanTerm')}
                      value={formatNumber(selectedPlan.loanTerm)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{t('common.months')}</InputAdornment>,
                      }}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t('merchant.promo.reducedRateDuration')}
                      value={formatNumber(selectedPlan.reducedRateDurationInMonths)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{t('common.months')}</InputAdornment>,
                      }}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t('merchant.promo.activeFrom')}
                      value={formatDate(selectedPlan.activeFrom)}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t('merchant.promo.expiresOn')}
                      value={formatDate(selectedPlan.expiresOn)}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )
        }}
      />
    </Box>
  )
}

export default MerchantPromoPanel
