import { useQuery } from '@tanstack/react-query'
import { getApiClient } from '../api-client'

const FEATURESWITCH = 'feature-switches'

const fiveMinutes = 300000

const keysFactory = {
  allFeatureSwitches: () => [FEATURESWITCH],
}

// Define the type for the feature flags
export type FeatureFlags = {
  useMerchantReports: boolean
}

const getFeatureFlags = async () => {
  const apiClient = getApiClient()
  const response = await apiClient.get<FeatureFlags>('/FeatureSwitch')
  return response.data
}

export default function useFeatureFlags(): [FeatureFlags | undefined, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.allFeatureSwitches(),
    queryFn: getFeatureFlags,
    staleTime: fiveMinutes,
    gcTime: fiveMinutes,
  })

  return [data, isFetching]
}
