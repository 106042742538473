import { BlockBlobClient } from '@azure/storage-blob'

const downloadDocument = async (filename: string, sasTokenUrl: string) => {
  try {
    const blockBlobClient = new BlockBlobClient(sasTokenUrl)
    const downloadResponse = await blockBlobClient.download()
    await downloadResponse.blobBody
    const blob = await downloadResponse.blobBody
    if (!blob) {
      throw new Error('Failed to download file')
    }
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error downloading file:', error)
    throw error
  }
}

export default downloadDocument
